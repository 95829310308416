export const isOnScreen = (elm) => {
	var rect = elm.getBoundingClientRect();
	var viewHeight = Math.max(
		document.documentElement.clientHeight,
		window.innerHeight
	);
	return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
};

export const seperator = (elm) => {
	return elm.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function checkZone(elm, targetedValue) {
	if (elm.textContent === 'Asia & Pacific') {
		switch (targetedValue) {
			case 'High':
				return { estimatedValue: 25.4 };
				break;
			case 'Avg':
				return { estimatedValue: 15 };
				break;
			case 'Low':
				return { estimatedValue: 4.84 };
				break;
			default:
				break;
		}
	} else if (elm.textContent === 'Europe, Middle East and Africa') {
		switch (targetedValue) {
			case 'High':
				return { estimatedValue: 21.46 };
				break;
			case 'Avg':
				return { estimatedValue: 12.86 };
				break;
			case 'Low':
				return { estimatedValue: 4.24 };
				break;
			default:
				break;
		}
	} else if (elm.textContent === 'North America') {
		switch (targetedValue) {
			case 'High':
				return { estimatedValue: 26.88 };
				break;
			case 'Avg':
				return { estimatedValue: 16.13 };
				break;
			case 'Low':
				return { estimatedValue: 5.38 };
				break;
			default:
				break;
		}
	} else if (elm.textContent === 'South America') {
		switch (targetedValue) {
			case 'High':
				return { estimatedValue: 23 };
				break;
			case 'Avg':
				return { estimatedValue: 12.47 };
				break;
			case 'Low':
				return { estimatedValue: 2.01 };
				break;
			default:
				break;
		}
	}
}
