import React, { Component } from "react";
import {directLinks} from '../../resources/directlinks';
import './sidebar.css';
class Footer extends Component{

	constructor(props){
		super(props);
		this.state={

		}
	}
	render() {
		return (
			<footer>
				  <div className="footer-main">
				    
				    <p>&copy;2021 Webtalk, Inc. | Affiliate ID: 123456</p>
				    <div className="footer-menu">
				      <ul>


				        <li><a rel="noopener noreferrer" href={directLinks.About.url} target="_blank" title="About">About</a></li>
				        <li><a rel="noopener noreferrer" href={directLinks.Products.url} target="_blank" title="Products">Products</a></li>
				        <li><a rel="noopener noreferrer" href={directLinks.Charity_pledge.url} target="_blank" title="10% Charity Pledge">10% Charity Pledge</a></li>
				        <li><a rel="noopener noreferrer" href={directLinks.Press.url} target="_blank" title="Press">Press</a></li>
				        <li><a rel="noopener noreferrer" href={directLinks.Legal.url} target="_blank" title="Legal">Legal</a></li>
				        <li><a rel="noopener noreferrer" href={directLinks.Help.url} target="_blank" title="Help">Help</a></li>


				      </ul>
				    </div>
				  </div>
				  
			</footer>

		);
	}

}

export default Footer;