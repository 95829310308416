import React from 'react';
import { seperator } from '../../resources/animationfunction';

import './top-earner-popup.css';
const CustomToastComponent = ({ currentNotificationData, load }) => {
	return (
		<div
			className={`wt-recent-users-toaster-popup ${
				load ? 'fade-in' : 'fade-out'
			}`}>
			<div className='avatar'>
				<div className='bg-container'>
					<img
						className='ng-tns-c0-0'
						src={currentNotificationData.avatar}
						alt={'avtar-img'}
					/>
				</div>
			</div>
			<div className='content'>
				<div className='ng-tns-c0-0'>
					<div className='truncateLabel'>
						<span className='name'>
							{currentNotificationData.first_name}{' '}
							{currentNotificationData.last_initial}{' '}
						</span>
						<span className='location '>
							{currentNotificationData.location && 'from '}

							{currentNotificationData.location &&
								(currentNotificationData.location.toLowerCase().includes('us')
									? currentNotificationData.location.replace(', US', '')
									: currentNotificationData.location)}
						</span>
					</div>
					<div className='recent ng-tns-c0-0 ng-star-inserted'>
						has earned{' '}
						<span className='amount'>
							${seperator(Math.floor(currentNotificationData.earnings))}
						</span>
					</div>
					<span className='reward ng-tns-c0-0 ng-star-inserted'>
						Webtalk Rewards Cash
					</span>
				</div>
			</div>
		</div>
	);
};

export default CustomToastComponent;
