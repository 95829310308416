import React, { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToButton from '../buttons/ScrollToButton';
import { headerfooterImages } from './sideBarImages';
import './sidebar.css';


class MobileHeader extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showLogo:false,
			hasClass:null
		}
		this.menuRef=React.createRef();
	}

	componentDidMount(){
		window.addEventListener("resize",this.showLogoView)
		this.showLogoView();
	}

	showLogoView=()=>{
		if(window.innerWidth<=767){
			this.setState({showLogo:true});
		}else if(window.innerWidth>767){
			this.setState({showLogo:false});
		}
	}

	toggleMenu=()=>{
		this.setState({hasClass:false});
	}

	openMenu=(e)=>{
		if(!this.menuRef.current.classList.contains("active")){
			this.setState({hasClass:true})
		}else{
			this.setState({hasClass:false})
		}
	}

	render() {
		return (
				<div className="header-main">
					
					<div className="logo">
						<Link to={this.props.SLUGS.home} title="Webtalk"><img src={this.state.showLogo ? headerfooterImages.mobile_website_logo.url : headerfooterImages.rbo_website_logo.url} alt={"Webtalk"} /></Link>
					</div>
					<div className="hamburger-menu">
						<input id="menu-toggle" type="checkbox" className={this.state.hasClass ? "active" :''} onClick={(e)=>this.openMenu(e)} ref={this.menuRef}/>
						<label className="menu-btn" htmlFor="menu-toggle">
							<span></span>
						</label>


						<div className="menu-box">
							<div className="logo">
								<Link to={this.props.SLUGS.home} title="Webtalk"><img src={headerfooterImages.rbo_website_logo.url} alt={"Webtalk"} /></Link>
							</div>

							<nav>
								<ul>
									<li className={this.props.SLUGS.home===window.location.pathname ? "active" :""}><Link to={this.props.SLUGS.home} onClick={(e)=>this.setState({hasClass:false})} title="Rewards">Rewards</Link></li>
									<li className={this.props.SLUGS.referralRewards===window.location.pathname ? "active" :""}><Link to={this.props.SLUGS.referralRewards} onClick={(e)=>this.setState({hasClass:false})} title="Referral Rewards">Referral Rewards</Link></li>
									<li className={this.props.SLUGS.opportunity===window.location.pathname ? "active" :""}><Link to={this.props.SLUGS.opportunity} onClick={(e)=>this.setState({hasClass:false})} title="Bonus Rewards">Bonus Rewards <span>limited</span></Link></li>
									<li className={this.props.SLUGS.successStories===window.location.pathname ? "active" :""}><Link to={this.props.SLUGS.successStories} onClick={(e)=>this.setState({hasClass:false})} title="Success Stories">Success Stories</Link></li>
									<li className={this.props.SLUGS.howitworks===window.location.pathname ? "active" :""}><Link to={this.props.SLUGS.howitworks} onClick={(e)=>this.setState({hasClass:false})} title="How It Works">How It Works</Link></li>
								</ul>
							</nav>

							<div className="side-nav">
								<ul>
									<li><Link className="btn btn-white" href="#" title="Sign In">Sign In</Link></li>
									<li  onClick={(e)=>this.toggleMenu(e)}><ScrollToButton/></li>
								</ul>
							</div>
						</div>
						<div className="menu-box-overlay" onClick={(e)=>this.toggleMenu(e)}>
						</div>
					</div>
					<div className="side-nav">
						<ul>
							<li><Link to={""} className="btn btn-white" title="Sign In">Sign In</Link></li>
							<li><ScrollToButton /></li>
						</ul>
					</div>
					{/* <!-- header section coding ends here-->*/}
				</div>
		);
	}

}

export default MobileHeader;