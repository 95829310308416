import React, { Component } from "react";
import { Link } from "react-router-dom";
import {headerfooterImages} from './sideBarImages';
import ScrollToButton from '../buttons/ScrollToButton';
import './sidebar.css';


class DesktopHeader extends Component{

	constructor(props){
		super(props);
		this.state={

		}
	}

	render() {
		return (
		          <div className="header-main">
				            {/*<!-- header section coding starts here-->*/}
				              <div className="logo">
				                <Link to={this.props.SLUGS.home} title="Webtalk"><img src={headerfooterImages.rbo_website_logo.url} alt={"Webtalk"}/></Link>
				              </div>

				              <nav>
				                <ul>
				                  <li className={this.props.SLUGS.home===window.location.pathname ? "active" :""}><Link to={this.props.SLUGS.home} title="Rewards">Rewards</Link></li>  
				                  <li className={this.props.SLUGS.referralRewards===window.location.pathname ? "active" :""}><Link to={this.props.SLUGS.referralRewards} title="Referral Rewards">Referral Rewards</Link></li>
				                  <li className={this.props.SLUGS.opportunity===window.location.pathname ? "active" :""}><Link to={this.props.SLUGS.opportunity} title="Bonus Rewards">Bonus Rewards <span>limited</span></Link></li>
				                  <li className={this.props.SLUGS.successStories===window.location.pathname ? "active" :""}><Link to={this.props.SLUGS.successStories} title="Success Stories">Success Stories</Link></li>
				                  <li className={this.props.SLUGS.howitworks===window.location.pathname ? "active" :""}><Link to={this.props.SLUGS.howitworks} title="How It Works">How It Works</Link></li>
				                </ul> 
				             </nav>

				             <div className="side-nav">
				               <ul>
				                 <li><Link to={""} className="btn btn-white" title="Sign In">Sign In</Link></li>
				                 <li><ScrollToButton/></li>
				               </ul>
				             </div>
				   {/* <!-- header section coding ends here-->*/}
				  </div>
		);
	}

}

export default DesktopHeader;