export const base_url=process.env.NODE_ENV==='production' ? "" : 'http://localhost:3000/';


export const headerfooterImages={
	rbo_website_logo:{
	        url:`${base_url}images/rbo-website-logo.svg`,  
	    },
	mobile_website_logo:{
	        url:`${base_url}images/mobile-logo.svg`,  
	    },
	
}
