import React, { Component } from 'react';
import Header from '../sidebar/header';
import Footer from '../sidebar/footer';
import CustomToastComponent from '../top-earner-popup/top-earner-popup';

import shuffle from 'lodash/shuffle';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			infoData: [],
			currentNotificationCount: 0,
			load: false,
		};
	}

	componentDidMount() {
		this.TopEarners();
	}

	TopEarners = async () => {
		fetch(`https://webtalk-app.herokuapp.com/WebTalksTopEarner`, {})
			.then((response) => response.json())
			.then((data) => {
				if (data.type === 'success') {
					this.setState({ infoData: shuffle(data.data), load: true }, () =>
						this.startNotifying()
					);
				}
			});
	};

	startNotifying = () => {
		this.setState({ load: true }, () => {
			this.intervalID = setInterval(this.countNotificationNumber, 12000);
		});
	};

	countNotificationNumber = () => {
		this.setState(
			{
				load: false,
			},
			() => {
				setTimeout(() => {
					if (
						this.state.currentNotificationCount <
						this.state.infoData.length - 1
					) {
						this.setState({
							load: true,
							currentNotificationCount: this.state.currentNotificationCount + 1,
						});
					} else {
						this.stopNotifying();
					}
				}, 6000);
			}
		);
	};

	stopNotifying = () => {
		this.setState(
			{
				load: false,
			},
			() => {
				clearInterval(this.intervalID);
			}
		);
	};

	render() {
		return (
			<>
				<Header />
				{this.props.children}
				{!!this.state.infoData.length && (
					<CustomToastComponent
						currentNotificationData={
							this.state.infoData[this.state.currentNotificationCount]
						}
						load={this.state.load}
					/>
				)}
				<Footer />
			</>
		);
	}
}

export default Layout;
