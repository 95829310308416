import React, { Component } from "react";
import { Link } from "react-router-dom";
var Scroll   = require('react-scroll');
var scroller = Scroll.scroller;           



class ScrollToButton extends Component{
  
  scrollToGetStarted=(e)=>{
      e.preventDefault();
      scroller.scrollTo('get-started', {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset:window.innerWidth<=767 ? -document.querySelector("header").clientHeight : -(Number(document.querySelector("body").getAttribute("offsetUp"))+Number(document.querySelector("body").getAttribute("offsetDown")) + document.querySelector("header").clientHeight)   , // Scrolls to element + 50 pixels down the page
      })
    }

		render(){
			return(
				<Link to={""} onClick={(e)=>this.scrollToGetStarted(e)} title="get started" className="btn">get started</Link>
			)
		}
}

export default ScrollToButton;