import React, { Component } from "react";
import SLUGS from '../../resources/slugs';
import DesktopHeader from './desktopheader';
import MobileHeader from './mobileheader';
import './sidebar.css';

class Header extends Component{

	constructor(props){
		super(props);
		this.state={
			showMenu:false,
			addClass:false
		}

		 this.headerSticky = React.createRef();
	}
	componentDidMount(){
		this.MenuItemShow();
		window.addEventListener("resize",this.MenuItemShow)
		window.addEventListener("scroll",this.checkScrolledHeader)
	}

	MenuItemShow=()=>{
		if(window.innerWidth<=991){
			this.setState({showMenu:true});
		}else if(window.innerWidth > 991){
			this.setState({showMenu:false})
		}
	}

	checkScrolledHeader=()=>{
		if(this.headerSticky.current!==null && document.documentElement.scrollTop>=this.headerSticky.current.clientHeight){
			this.setState({addClass:true});
		}else{
			this.setState({addClass:false});
		}
	}



	render() {
		return (
			<header ref={this.headerSticky} className={this.state.addClass ? "sticky-fixed" : ""}>			
				{this.state.showMenu ? <MobileHeader SLUGS={SLUGS}  /> : <DesktopHeader SLUGS={SLUGS} />}
			</header >
		);	
	}

}

export default Header;