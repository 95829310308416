import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from '../resources/slugs';
import Loader from '../components/loading/Loader';
import Layout from '../components/layout/layout';

const ReactLazyPreload = importStatement => {
  const Component = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const Home = ReactLazyPreload(() => import('./Home'));
const HowItWorks = ReactLazyPreload(() => import('./How-it-works'));
const Opportunity = ReactLazyPreload(() => import('./Opportunity'));
const ReferralRewards = ReactLazyPreload(() => import('./Referral-rewards'));
const SuccessStories = ReactLazyPreload(() => import('./Success-stories'));

function PublicRoutes() {
	return (
		<Suspense fallback={<Loader loading />}>
			<Layout>
				<Switch>
					<Route exact path={SLUGS.home} component={Home} />
					<Route path={SLUGS.howitworks} component={HowItWorks} />
					<Route path={SLUGS.opportunity} component={Opportunity} />
					<Route path={SLUGS.opportunity} component={Opportunity} />
					<Route path={SLUGS.referralRewards} component={ReferralRewards} />
					<Route path={SLUGS.successStories} component={SuccessStories} />
					<Redirect to={SLUGS.home} />
				</Switch>
			</Layout>
		</Suspense>
	);
}

export default PublicRoutes;
