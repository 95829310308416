export const directLinks={
	About:{
		url:`https://www.newsroom.webtalk.co/about`
	},
	Products:{
		url:`https://www.newsroom.webtalk.co/products`
	},
	Charity_pledge:{
		url:`https://www.newsroom.webtalk.co/philanthropy`
	},
	Press:{
		url:`https://www.newsroom.webtalk.co/news`
	},
	Legal:{
		url:`https://www.newsroom.webtalk.co/legal`
	},
	Help:{
		url:`https://help.webtalk.co/hc/en-us`
	}
}