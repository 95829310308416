import React, { Component } from "react";
import './Loader.css';


class Loader extends Component {
	render() {
		return (
			<div className="load-overlay" >
	          	<div className="overlayContent">
		            <div className="loader">
		              <div className="inner"></div>
		            </div>
          		</div>
   			</div>
		);
	}

}

export default Loader;