import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';
import {isOnScreen} from './resources/animationfunction';

window.addEventListener("scroll",()=>{
	document.querySelectorAll(".animate-fade").forEach((item,index)=>{
		if(isOnScreen(document.querySelectorAll(".animate-fade")[index])){
                    setTimeout(function(){
                        document.querySelectorAll(".animate-fade")[index].classList.add("animation-in");
                    })
                }
	})
  });


ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Routes />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
